.edit {
  img {
    width: 23px;
    height: 23px;
  }

  &__add {
    border: 1px red solid;
  }

  &__item {
    border: 1px blue solid;
  }
}