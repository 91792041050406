.add {
  width: 92%;
  margin: 0 auto;
  z-index: 20;
  margin-top: 50px;

  &__images {
    margin: 1rem 0;
    input {
      border-radius: 20px;
      font-size: 24px;
    }

    input[type="file"] {
      display: none;
    }

    .custom-file-upload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      color: black;
      border-radius: 20px;
      cursor: pointer;
    }
  }

  &__id {
    width: 100%;
    input {
      font-size: 25px;
      padding: 0.5rem;
      width: 90%;
      border: 1px solid #ccc;
      border-radius: 10px;
    }
  }

  &__year {
    margin: 1rem;
    padding: 10px;
    color: white;
    background-color: black;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
  }

  &__category {
    margin: 1rem;
    padding: 10px;
    color: white;
    background-color: black;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
  }

  &__send {
    button {
          margin: 1rem;
    padding: 10px 70px;
    color: white;
    background-color: black;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    border: 0;
    }

  }


 
}
