.post {
  display: grid;
  grid-template-columns: 1fr;
  margin: auto 1rem;
  padding-top: 0.5rem;

  @include media("tablet") {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__card {
    width: 100%;
    height: auto;

    @include media("tablet") {
      width: auto;
      height: 700px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.article {
  &__title  {
    margin-left: 1rem;
    margin-top: 0;
  }
}
