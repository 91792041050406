.card {
  margin-bottom: 1rem;

  img {
    border-radius: 10px;
    border: 2px solid black;
  }

  h2 {
    margin: 0rem 1rem;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 25px;
    text-align: left;
    line-height: 22px;
  }
}

.card-home {
  &__image {
    height: 420px;

    img {
      width: 100%;
      object-fit: cover;
      height: 100%;
    }
  }
}