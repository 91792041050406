.navigation {
  display: flex;
  align-items: center;
  margin-top: 0;

  &__logo {
    font-weight: 500;
    font-size: 50px;
    letter-spacing: -3px;
    margin-top: 7px;
    margin-left: 1rem;
    z-index: 100;

    @include media("tablet") {
      margin-top: 25px;
      margin-left: 30px;
    }
  }

  &__links {

    position: block !important;

    margin: 1rem 0;

    @include media("tablet") {
      margin: 0;
    }

    a {
      transition: all ease 0.3s;
      border-radius: 20px;
    }

    &--forestando {
      color: $green;
    }

    &--roxanne {
      color: $red;
    }

    &--projects {
      color: $blue;

    }
  }
}


#menu {
  z-index: 2;
}
.menu-bar-contanier{
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#menu-bar {
  width: 40px;


  height: 50px;
  
  cursor: pointer;
  margin: 38px 22px 0;
  left: 0;

  @include media("tablet") {
    width: 50px;
    margin: 50px 40px;
  }


}

.bar {
  height: 2px;
  margin-bottom: 7px;
  width: 100%;
  background-color: #000;
  display: block;
  border-radius: 0px;
  transition: 0.3s ease;
}

#bar1 {
  transform: translateY(-4px);
}

#bar3 {
  transform: translateY(4px);
}

.nav {
  transition: 0.3s ease;
  display: none;
  height: 70vh;
  transition: visibility 0s, opacity 0.5s linear;
}

.nav ul {
  padding: 0 20px;

  @include media('tablet') {
    padding: 0 25px;
  }
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: 1s ease;
  background: white;
}

.nav li {
  list-style: none;
  transition: 1s ease;
}

.nav li a {
  font-size: 50px;
  text-decoration: none;
  transition: 1s ease;
  letter-spacing: -3px;
  @include media("tablet"){
    font-size: 70px;
  }
}

.menu-bg, #menu {
  top: 0;
  right: 0;
  width: 100%;
  position: absolute;
  background: white;
}

.menu-bg {
  z-index: 1;
  width: 0;
  height: 0;
  background: $white;
}

.change {
  display: block;
  transition: visibility 0s, opacity 0.5s linear;
}

// .change > ul {
//   visibility: hidden;
//   opacity: 0;
//   transition: visibility 0s, opacity 0.5s linear;
// }

// .change:hover > ul {
//   visibility: visible;
//   opacity: 1;
// }

.change + .nav {
  transition: .3s ease;
}

.change .bar {
  background-color: black;
}

.change #bar1 {
  transform: translateY(10px) rotateZ(-45deg);
}

.change #bar2 {
  opacity: 0;
}

.change #bar3 {
  transform: translateY(-7px) rotateZ(45deg);
}

.change-bg {
  width: 100%;
  height: 100vh;
}

.admin {

  img {
      width: 100%;
  max-width: 200px;
  height: auto;
  }

}

.forest {
  color: #36723E;
}

.ando {
  color: #36723E;
  color: #86A54F;
}